
import { Class, CommonError, EditableUser, Student, errorPrepend } from '@/models';
import { defineComponent } from 'vue';
import NoContent from '@/components/common/NoContent.vue';
import { confirmDelete, showError } from '@/sweetalert2/templates.ts';
import ChangeClassNameModal from '@/components/organizer/ChangeClassNameModal.vue';
import ChangeClassModal from '@/components/organizer/ChangeClassModal.vue';
import AddStudentModal from '@/components/organizer/AddStudentModal.vue';

export default defineComponent({
  name: 'ClassManagement',
  components: { AddStudentModal, ChangeClassModal, ChangeClassNameModal, NoContent },

  data () {
    return {
      classes: [] as Class[],
      search: '',
      newName: '',
      newClassName: '',
      students: [] as Student[],
      newStudentClass: null,
      changeClassValues: {
        classes: [] as Class[],
        student: {} as Student,
        classId: -1 as number
      },
      addStudentValues: {
        students: [] as Student[],
        classVal: { name: '', students: [], id: -1, schoolId: -1 } as Class
      },
      changeClassNameValues: {
        classVal: {} as Class
      }
    };
  },
  async mounted () {
    try {
      await this.fetchData();
    } catch (e) {
      await showError(this.$t("messages.no data load") + errorPrepend());
    }
  },
  computed: {
    filteredClasses (): Class[] {
      const terms = this.search.split(' ');
      const matchedClasses = this.classes.filter(c => terms.reduce((a: boolean, b: string) => a || c.name.toLowerCase().includes(b.toLowerCase()), false));
      const matchedStudents = this.classes.map(c => {
        const newClass = Object.create(c);
        newClass.students = c.students?.filter((s: EditableUser) =>
          terms.reduce((a: boolean, b: string) => a && (
            s.id === -1 ||
            s.firstname.toLowerCase().includes(b.toLowerCase()) ||
            s.lastname.toLowerCase().includes(b.toLowerCase())), true)) ?? [];
        return newClass;
      }).filter(c => c.students && c.students.length !== 0);
      return matchedClasses.concat(matchedStudents.filter((s) => matchedClasses.findIndex(c => s.id === c.id) < 0));
    }
  },
  methods: {
    async fetchData () {
      const allClasses = await Class.getAll();
      this.classes = (allClasses).sort((a, b) => a.name.localeCompare(b.name));
      this.classes.forEach(c => c.students.sort((a, b) => a.lastname.localeCompare(b.lastname)));
      this.students = (await Student.getAll()).sort((a, b) => a.lastname.localeCompare(b.lastname));
    },
    async changeName (event: { class: Class, newName: string }) {
      event.class.name = event.newName;
      try {
        await Class.update(event.class);
      } catch (e) {
        await showError(this.$t("messages.no class change") + errorPrepend());
      }
    },
    async deleteClass (classVal: Class) {
      const result = await confirmDelete(this.$t("messages.Delete?"), `${classVal.name} ${this.$t("messages.Delete?")} ${this.$t("messages.no return")}`);
      if (!result.isConfirmed) {
        return;
      }
      try {
        await Class.delete(classVal.id);
        await this.fetchData();
      } catch (e) {
        await showError(this.$t("messages.error class load") + errorPrepend());
      }
    },
    async createClass (newClassName: string) {
      if (newClassName.trim() === '') {
        await showError(this.$t("messages.fill all fields"));
        return;
      }
      try {
        await Class.create({ name: newClassName } as Class);
        this.newClassName = '';
        (this.$refs.newClass as HTMLInputElement).focus();
        await this.fetchData();
      } catch (e) {
        await showError(this.$t("messages.no class add") + errorPrepend());
      }
    },
    async changeClass (event: { student: Student, newClass: number }) {
      try {
        await Student.updateClass(event.newClass, event.student.id);
        await this.fetchData();
      } catch (e) {
        await showError(this.$t("messages.no students class change") + errorPrepend());
      }
    },
    async updateStudents (event: { class: Class, students: Student[] }) {
      try {
        await Class.updateStudents(event.class.id, [...event.students, ...event.class.students]);
        await this.fetchData();
      } catch (e) {
        await showError(this.$t("messages.no class change") + errorPrepend());
      }
    },
    async removeStudentFromClass (student: Student, classId: number) {
      const classVal = this.classes.find(c => c.id === classId) ?? {} as Class;
      const students = [...classVal.students];
      students.splice(classVal.students.findIndex(s => s.id === student.id), 1);
      try {
        await Class.updateStudents(classVal.id, students);
        await this.fetchData();
      } catch (e) {
        await showError(this.$t("messages.error class load") + errorPrepend());
      }
    },
    changeClassModal (classes: Class[], student: Student, classId: number) {
      this.changeClassValues = {
        classes,
        student,
        classId
      };

      (this.$refs.changeClassModal as typeof ChangeClassModal).openModal();
    },
    addStudentModal (students: Student[], classVal: Class) {
      this.addStudentValues = {
        students,
        classVal
      };
      (this.$refs.addStudentModal as typeof AddStudentModal).openModal();
    },
    changeClassNameModal (classVal: Class) {
      this.changeClassNameValues = {
        classVal
      };
      (this.$refs.changeClassNameModal as typeof ChangeClassNameModal).openModal();
    }
  }
});
