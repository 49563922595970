import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelCheckbox as _vModelCheckbox, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-ec90c4b4")
const _hoisted_1 = ["placeholder"]
const _hoisted_2 = {
  key: 0,
  class: "h-75vw"
}
const _hoisted_3 = { class: "col-12 ml-1 mt-1" }
const _hoisted_4 = ["id", "value"]
const _hoisted_5 = ["for"]
const _hoisted_6 = {
  key: 1,
  class: "row d-flex justify-content-center"
}
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NoContent = _resolveComponent("NoContent")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    id: `addStudent${_ctx.classVal.id}`,
    ref: "modal"
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t("messages.Add student")), 1)
    ]),
    modalBody: _withCtx(() => [
      _createElementVNode("p", null, _toDisplayString(_ctx.$t("messages.add student to class")) + " " + _toDisplayString(_ctx.classVal.name) + ": ", 1),
      _withDirectives(_createElementVNode("input", {
        type: "text",
        placeholder: _ctx.$t('messages.search term'),
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.search = $event)),
        class: "form-control mb-2"
      }, null, 8, _hoisted_1), [
        [_vModelText, _ctx.search]
      ]),
      (_ctx.filteredStudents.length !== 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredStudents, (student) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "row",
                key: student.id
              }, [
                _createElementVNode("div", _hoisted_3, [
                  _withDirectives(_createElementVNode("input", {
                    type: "checkbox",
                    id: `${_ctx.classVal.id}studentCheck${student.id}`,
                    value: student,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.newStudents = $event))
                  }, null, 8, _hoisted_4), [
                    [_vModelCheckbox, _ctx.newStudents]
                  ]),
                  _createElementVNode("label", {
                    class: "px-2",
                    for: `${_ctx.classVal.id}studentCheck${student.id}`
                  }, _toDisplayString(student.firstname) + " " + _toDisplayString(student.lastname), 9, _hoisted_5)
                ])
              ]))
            }), 128))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_component_NoContent, {
              text: _ctx.$t('messages.no students')
            }, null, 8, ["text"])
          ]))
    ]),
    openButton: _withCtx((props) => [
      _createElementVNode("button", _mergeProps(props, { class: "invisible" }), null, 16)
    ]),
    modalFooter: _withCtx(() => [
      _createElementVNode("button", {
        class: "btn btn-primary",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.addStudents()))
      }, _toDisplayString(_ctx.$t("messages.Add student")), 1)
    ]),
    _: 1
  }, 8, ["id"]))
}