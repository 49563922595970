import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, mergeProps as _mergeProps, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-4061608f")
const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12 form-inline justify-content-around" }
const _hoisted_3 = ["for"]
const _hoisted_4 = ["id", "title"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    id: `changeName${_ctx.classVal.id}`,
    ref: "modal"
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.classVal.name) + " " + _toDisplayString(_ctx.$t("messages.Edit")), 1)
    ]),
    modalBody: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("label", {
            for: `className${_ctx.classVal.id}`
          }, _toDisplayString(_ctx.$t("messages.New name")), 9, _hoisted_3),
          _withDirectives(_createElementVNode("input", {
            id: `className${_ctx.classVal.id}`,
            title: _ctx.$t('messages.class name'),
            type: "text",
            class: "form-control",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.newName = $event))
          }, null, 8, _hoisted_4), [
            [_vModelText, _ctx.newName]
          ])
        ])
      ])
    ]),
    modalFooter: _withCtx(() => [
      _createElementVNode("button", {
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.changeName && _ctx.changeName(...args))),
        class: "btn btn-primary"
      }, _toDisplayString(_ctx.$t("messages.change name")), 1)
    ]),
    openButton: _withCtx((props) => [
      _createElementVNode("button", _mergeProps(props, { class: "invisible" }), null, 16)
    ]),
    _: 1
  }, 8, ["id"]))
}