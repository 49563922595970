
import Modal from '@/components/common/Modal.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ChangeClassModal',
  components: { Modal },
  data () {
    return { newStudentClass: -1 };
  },
  emits: ['changeClass'],
  props: {
    classes: {},
    student: {},
    classId: { type: Number, required: true }
  },
  mounted () {
    this.newStudentClass = this.classId;
  },
  methods: {
    changeClass () {
      this.$emit('changeClass', { student: this.student, newClass: this.newStudentClass });
      (this.$refs.ChangeClassModal as typeof Modal).closeModal();
    },
    openModal () {
      (this.$refs.ChangeClassModal as typeof Modal).openModal();
    }
  },
  watch: {
    classId (newVal) {
      this.newStudentClass = newVal;
    }
  }
});
