import { mergeProps as _mergeProps, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-4e84b202")
const _hoisted_1 = ["for"]
const _hoisted_2 = ["id"]
const _hoisted_3 = ["value", "selected"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    ref: "ChangeClassModal",
    id: 'studentModal'+_ctx.student.id
  }, {
    openButton: _withCtx((slotProps) => [
      _createElementVNode("button", _mergeProps(slotProps, { class: "invisible" }), null, 16)
    ]),
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t("messages.change class")), 1)
    ]),
    modalBody: _withCtx(() => [
      _createElementVNode("label", {
        for: `studentSelect${_ctx.student.id}`
      }, _toDisplayString(_ctx.$t("messages.Class of")) + " " + _toDisplayString(_ctx.student.firstname) + " " + _toDisplayString(_ctx.student.lastname), 9, _hoisted_1),
      _withDirectives(_createElementVNode("select", {
        class: "form-control",
        name: "class",
        ref: "select",
        id: `studentSelect${_ctx.student.id}`,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.newStudentClass = $event))
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.classes, (classVal) => {
          return (_openBlock(), _createElementBlock("option", {
            key: classVal.id,
            value: classVal.id,
            selected: _ctx.classId === classVal.id
          }, _toDisplayString(classVal.name), 9, _hoisted_3))
        }), 128))
      ], 8, _hoisted_2), [
        [_vModelSelect, _ctx.newStudentClass]
      ])
    ]),
    modalFooter: _withCtx(() => [
      _createElementVNode("button", {
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.changeClass && _ctx.changeClass(...args))),
        class: "btn btn-primary"
      }, _toDisplayString(_ctx.$t("messages.change class")), 1)
    ]),
    _: 1
  }, 8, ["id"]))
}