
import Modal from '@/components/common/Modal.vue';
import { defineComponent, PropType } from 'vue';
import { Class } from '@/models';

export default defineComponent({
  name: 'ChangeClassNameModal',
  components: { Modal },
  data () {
    return {
      newName: ''
    };
  },
  emits: ['changeName'],
  props: {
    classVal: { type: Object as PropType<Class> }
  },
  methods: {
    changeName () {
      if (this.newName !== '') {
        this.$emit('changeName', { class: this.classVal, newName: this.newName });
        (this.$refs.modal as typeof Modal).closeModal();
      }
    },
    openModal () {
      (this.$refs.modal as typeof Modal).openModal();
    }
  }
});
