
import { defineComponent, PropType } from 'vue';
import Modal from '@/components/common/Modal.vue';
import { Class, Student } from '@/models';
import NoContent from '@/components/common/NoContent.vue';

export default defineComponent({
  name: 'AddStudentModal',
  components: { NoContent, Modal },
  data () {
    return {
      search: '',
      newStudents: [] as Student[],
      selected: { id: -1 } as Student
    };
  },
  emits: ['addStudents'],
  props: {
    classVal: {
      required: true, type: Object as PropType<Class>
    },
    students: {
      required: true,
      type: Object as PropType<Student[]>
    }
  },
  mounted () {
    this.newStudents = this.classVal.students;
  },
  methods: {
    addStudents () {
      this.$emit('addStudents', { class: this.classVal, students: this.newStudents });
      this.newStudents = [];
      (this.$refs.modal as typeof Modal).closeModal();
    },
    openModal () {
      (this.$refs.modal as typeof Modal).openModal();
    }
  },
  computed: {
    studentsWithClass (): Student[] {
      return this.filteredStudents.filter(s => s.classId !== null);
    },
    studentsWithoutClass (): Student[] {
      return this.filteredStudents.filter(s => s.classId === null);
    },
    filteredStudents (): Student[] {
      let terms = this.search.split(' ');
      terms = terms.filter(t => t.length !== 0);
      const students = this.students.filter((item) => this.classVal.students.findIndex(s => s.id === item.id) === -1);
      if (this.search === '') {
        return students;
      }
      return students.filter(c => terms.reduce((a: boolean, b: string) => a || c.firstname.toLowerCase().includes(b.toLowerCase()) || c.lastname.toLowerCase().includes(b.toLowerCase()), false));
    }
  }

});
